/**
 * @generated SignedSource<<a8d55291decd28fc93f4207027fc2694>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HeroButton_contentBlock$data = {
  readonly " $fragmentSpreads": FragmentRefs<"FlowRouterButton_button">;
  readonly " $fragmentType": "HeroButton_contentBlock";
};
export type HeroButton_contentBlock$key = {
  readonly " $data"?: HeroButton_contentBlock$data;
  readonly " $fragmentSpreads": FragmentRefs<"HeroButton_contentBlock">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HeroButton_contentBlock",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FlowRouterButton_button"
    }
  ],
  "type": "Button",
  "abstractKey": null
};

(node as any).hash = "0d5261aadfa50c666e78fde13c0d7e80";

export default node;
